import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/react/dialog/Dialog.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/react/dialog" storybook="https://workday.github.io/canvas-kit/?path=/story/components-popups-dialog--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=21264-75556&t=UhTeJ6F4tDSMVJmL-4" package="@workday/canvas-kit-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/f97d30aead153f665604bdb6a26c169d/b5a09/dialog-anatomy.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "53.51351351351351%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a dialog container with annotation markers.",
              "title": "Image of a dialog container with annotation markers.",
              "src": "/static/f97d30aead153f665604bdb6a26c169d/50383/dialog-anatomy.png",
              "srcSet": ["/static/f97d30aead153f665604bdb6a26c169d/1efb2/dialog-anatomy.png 370w", "/static/f97d30aead153f665604bdb6a26c169d/50383/dialog-anatomy.png 740w", "/static/f97d30aead153f665604bdb6a26c169d/b5a09/dialog-anatomy.png 1360w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Card`}</strong>{`: The Card contains content for a Dialog, it uses the depth 6 token for the drop-shadow
styling.`}</li>
        <li parentName="ol"><strong parentName="li">{`Heading (Optional)`}</strong>{`: Heading should display the title of the content or task.`}</li>
        <li parentName="ol"><strong parentName="li">{`Body`}</strong>{`: Dialogs contain many different types of content in the body. Typical types of content
include media, alerts, dialogs, and/or task-oriented flows.`}</li>
        <li parentName="ol"><strong parentName="li">{`In-line Buttons (Optional)`}</strong>{`: Action should be at the bottom of the container when used. There
are multiple alignments available for use; Left (Default), Center, Full Width & Full Width
Stacked, or Right aligned.`}</li>
        <li parentName="ol"><strong parentName="li">{`Close “X” Icon (Optional)`}</strong>{`: Users must be able to intentionally dismiss a Dialog. This icon
inherits styling and interactions from our Tertiary Icon-Only Button Variant.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <ul>
        <li parentName="ul">{`Dialogs allow for entry of data or alert users on any given page after an action has been
initiated and it doesn't require immediate attention.`}</li>
        <li parentName="ul">{`On web platforms with browser windows 767px or wider, Dialogs show up next to the button that
activated it.`}</li>
        <li parentName="ul">{`On web platforms with browser windows less than 767px width, Dialogs show up at the bottom of the
screen and in front of an overlay.`}</li>
        <li parentName="ul">{`Dialogs are often used to display media, alerts, dialogs, and/or task-oriented flows. Links,
buttons, field sets, icons, text inputs, and prompts can all exist within Dialogs.`}</li>
        <li parentName="ul">{`In-line buttons used in Dialogs can be aligned Left (Default), Center, Full Width & Full Width
Stacked, or Right aligned.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <ul>
        <li parentName="ul">{`Use Dialog to gather input from the user without blocking interaction with the rest of the page.`}</li>
        <li parentName="ul">{`Use Dialog when alert content and text are too large for a standard Toast or Pop-up notification.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When to Use Something Else`}</h3>
      <ul>
        <li parentName="ul">{`Use `}<a parentName="li" {...{
            "href": "/components/popups/modal"
          }}>{`Modal`}</a>{` to gather immediate input from the user by blocking
interaction with the rest of the page.`}</li>
        <li parentName="ul">{`Do not use Dialogs to serve up easily accessible links or simple messages that can be dismissed
quickly (use `}<a parentName="li" {...{
            "href": "/components/popups/toast"
          }}>{`Toasts`}</a>{` or `}<a parentName="li" {...{
            "href": "/components/popups/popup"
          }}>{`Popups`}</a>{` for this).`}</li>
        <li parentName="ul">{`Do not use Dialogs to display dense information, such as Tables or Multi-View Containers.`}</li>
        <li parentName="ul">{`Consider a `}<a parentName="li" {...{
            "href": "/components/popups/toast"
          }}>{`Toast`}</a>{` if you are communicating status or confirmation of
the application process to the user.`}</li>
        <li parentName="ul">{`Consider a `}<a parentName="li" {...{
            "href": "/components/popups/menu"
          }}>{`Menu`}</a>{` if the input is a single selection of options.`}</li>
      </ul>
      <h3 {...{
        "id": "responsive-view"
      }}>{`Responsive View`}</h3>
      <p>{`Dialog components adjust width and content presentation based on screen size. When content exceeds
the length of the screen, the Dialog content will become scrollable in the body section of the
Dialog. For long content on a small screen, inline buttons will continue to scroll with the content.`}</p>
      <h3 {...{
        "id": "touch-based-behavior"
      }}>{`Touch Based Behavior`}</h3>
      <p>{`The overlay on Dialogs are not click or touch enabled to close the Dialog component view on small
screens between 320-767px. This accounts for accidental touch on mobile devices. Background overlays
will close the Dialog when clicked on larger devices when the screen reaches the minimum width.`}</p>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <p>{`The accessibility for the Dialog component is identical to the guidelines detailed in the Modal
component. Please refer to the accessibility guidelines in the
`}<a parentName="p" {...{
          "href": "/components/popups/modal#tab=web&heading=accessibility-guidelines"
        }}>{`Modal popup component`}</a>{`.`}</p>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <p>{`See `}<a parentName="p" {...{
          "href": "/guidelines/content/ui-text/dialogs"
        }}>{`Popups, Modals, and Dialogs`}</a>{` in the UI Text Section of
Content Style Guide for Dialog language guidelines.`}</p>
    </TabPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      